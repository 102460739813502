<template>
  <v-row class="mx-5 py-8">
    <v-col
      v-for="(game, index) in games"
      :key="index"
      cols="12"
      md="6"
      lg="4"
      xl="3"
    >
      <ag-course-card
        :title="game.title"
        :icon="game.status === 'finished' ? 'mdi-check' : null"
        :disabled="game.status === 'closed'"
        :inactive="game.status === 'finished'"
        :img="`images/games/${game.name}.jpg`"
        :to="`/${game.name}/${game.resourceId}`"
        :button-text="$t(`home.game.status.${game.status}`)"
        :button-color="cardButtonColors[game.status]"
        style="height: 164px"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AgCourseCard from 'src/components/common/AgCourseCard'

export default {
  name: 'HomeContainer',
  components: {
    AgCourseCard,
  },
  computed: {
    ...mapGetters('session', {
      gameList: 'gameList',
      sessionCode: 'sessionCode',
    }),
    ...mapGetters('me', {
      fullname: 'fullname',
    }),
    cardButtonColors () {
      return {
        closed: 'secondary',
        opened: 'accent',
        finished: 'primary',
      }
    },
    games () {
      return this.gameList.map(game => ({
        ...game,
        title: this.$t(`home.game.title.${game.name}`),
      }))
    },
    title () {
      return this.$t('home.title', {
        sessionCode: this.sessionCode,
        playerName: this.fullname,
      })
    },
  },
  watch: {
    title: {
      immediate: true,
      handler () {
        this.setTitle(this.title)
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      setTitle: 'setTitle',
    }),
  },
}
</script>

<style lang="scss" scoped>
.card-game {
  transition: opacity 1s;
}
.card-game-closed {
  opacity: 0.7;
}

.card-game-finished {
  opacity: 0.8;
}

.icon-game {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 10vmax;
  transform: translate(-50%, -50%);
  opacity: 0.6;
}
</style>
