<template>
  <v-card
    :class="{'ag-course-card--active': !inactive && !disabled}"
    class="ag-course-card rounded-lg"
    flat
  >
    <v-overlay
      :value="disabled"
      color="grey"
      opacity="0.7"
      absolute
    />
    <v-row align="stretch" no-gutters class="fill-height">
      <v-col
        cols="5"
        class="ag-course-card--picture d-flex align-center justify-center"
        :style="`background-image: ${backgroundImageCss}`"
      >
        <v-icon
          v-if="icon"
          class="ag-course-card--icon"
          dark
          v-text="icon"
        />
      </v-col>

      <v-col class="d-flex flex-column align-center py-5">
        <div class="flex-grow-1 d-flex flex-column justify-center mb-5">
          <div
            class="text-h5 text-center grey--text text--darken-3 font-weight-medium"
            v-text="title"
          />
        </div>
        <v-btn
          :disabled="inactive"
          :depressed="inactive || disabled"
          :small="inactive || disabled"
          :color="buttonColor"
          :to="to"
          rounded
          v-text="buttonText"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'AgCourseCard',
  props: {
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonColor: {
      type: String,
      default: null,
    },
    inactiveGradientColor: {
      type: String,
      default: 'rgba(45, 86, 109, 0.7)',
    },
  },
  computed: {
    backgroundImageCss () {
      const backgroundUrl = `url(${this.img})`
      const backgroundGradient = `linear-gradient(${this.inactiveGradientColor}, ${this.inactiveGradientColor})`
      return this.inactive
        ? `${backgroundGradient}, ${backgroundUrl}`
        : backgroundUrl
    },
  },
}
</script>

<style lang="scss" scoped>
.ag-course-card {
  overflow: hidden !important;
  transition: all 0.1s linear !important;
}

.ag-course-card--active:hover {
  transform: translateY(-5px) scale(1.02) !important;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3) !important;
}

.ag-course-card--picture {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ag-course-card--icon {
  font-size: 8rem !important;
}
</style>
